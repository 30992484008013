.form.form-fixed-actions {
    padding-bottom: $spacer*8;
}
.form-control-description {
    padding: $spacer $spacer*3;
    border-left: 3px solid $border-color;
    p {
        margin-top: $spacer;
        margin-bottom: $spacer;
    }
}
.form-control[type=search] {
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#{$input-placeholder-color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search icon"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>');
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 18px;
    padding-right: $spacer*4;
    &.form-control-sm {
        background-position: right 8px center;
        background-size: 16px;
        padding-right: $spacer*3;
    }
}
.form-control-sm {
    height: $spacer*3;
}

.custom-checkbox--circle {
    .custom-control-label::before {
        border-radius: 50%;
        border: none;
        background-color: rgba($gray-400, .6);
    }
    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow:0 0 0 0.2rem rgba($gray-400, .25);
    }
}
.custom-checkbox--lg {
    .custom-control-label::after,
    .custom-control-label::before {
        top: unset;
        height: $spacer*2;
        width: $spacer*2;
        cursor: pointer;
    }
    .custom-control-input ~ .custom-control-label::after {
        background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$gray-100}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"), "#", "%23");
    }
}

.form-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: $spacer*4;
    padding-top: $spacer*2;
    border-top: 1px solid $border-color;
}

.form.form-fixed-actions {
    .form-actions {
        position: fixed;
        bottom: 0;
        left: $toolbar-width + ($spacer*2);
        right: 0;
        padding: $spacer*2 $spacer*3;
        z-index: 12;
        background: #fff;
    }
}
.sidebar-open .form.form-fixed-actions {
    .form-actions {
        left: $sidebar-width + $toolbar-width;
    }
}

.invalid-feedback {
    margin-bottom: 0;
    margin-top: $spacer;
}

.input-group-prepend {
    .custom-select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.input-group-append {
    .custom-select {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
.input-group-text {
    background: $secondary;
    color: $darker;
}
textarea.form-control {
    min-height: 118px;
}


.part-type-selector {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: $spacer*2;
    grid-row-gap: $spacer*2;
    margin-bottom: $spacer*2;
    .part-type-item {
        cursor: pointer;
        display: flex;
        width: 100%;
        height: 220px;
        padding: $spacer*3 $spacer*2 $spacer*2 $spacer*2;
        background: $gray-100;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        color: $dark;
        border: 1px solid $border-color;
        transition: box-shadow .1s, transform .1s;
        &:hover {
            color: $dark;
            text-decoration: none;
            box-shadow: 0 2px 11px rgba($dark, .125);
            transform: translateY(-5px);
            transition: box-shadow .1s, transform .1s;
        }
    }
    .part-type-item-title {
        display: block;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: $spacer;
    }
    .part-type-item-icon {
        height: 82px;
        width: 82px;
        border-radius: 50%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-bottom: $spacer*1.5;
        background: $teal;
        .feather {
            height: 50%;
            width: 50%;
            stroke-width: 1.3;
        }
    }
    .part-type-item--selected {
        background: $teal;
        color: $white;
        box-shadow: 0 2px 11px rgba($dark, .125);
        transform: translateY(-5px);
        border-color: $teal;
        transition: all .2s;
        .part-type-item-icon { 
            transition: all .2s;
            background: $white;
            color: $teal;
        }
        &:hover {
            color: $white;;
        }
    }
}

.check-circle {
    background: $gray-200;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center; 
    &.check-circle-active {
        background: $teal;
        .feather {
            stroke: #fff;
        }
    }
    .feather {
        height: 16px;
        width: 16px;
        stroke: $gray-200;
    }
}
.custom-file {
    // border: 1px solid $border-color;
    // border-bottom-left-radius: $border-radius-sm;
    // // border-bottom-right-radius: $border-radius;
}
.custom-file-label {
    // border: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.custom-file-label-no-browse::after {
    display: none;
}

.files-list {
    th,td {
        vertical-align: middle;
        white-space: nowrap;
        .feather {
            vertical-align: middle;
        }
    }
    .custom-file,
    .custom-file-input,
    .custom-file-label {
        height: $spacer*2;
        padding: 0;
        margin-bottom: 0;
        background-color: transparent;
        overflow: hidden;
        text-overflow: ellipsis;
        outline: none;
        border-radius: 0;
        display: block;
    }
    .custom-file-label {
        border: none;
        &::after {
            display: none;
        }
    }
    .btn {
        // display: block;
        margin-bottom: 0;
    }
    .btn-unstyled .feather {
        display: block !important;
    }
}


.file-upload-drawer {
    margin-top: $spacer;
    background-color: $gray-100;
    padding: $spacer;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    position: relative;
    .file-upload-drawer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    img {
        width: 30px;
    }
    .file-upload-drawer-link {
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .file-upload-drawer-remove {
        margin-right :$spacer;
        margin-left: $spacer;
    }
    .file-upload-drawer-download-icon,
    .file-upload-drawer-link,
    .file-upload-drawer-remove   {
        color: $gray-500;
        &:hover {
            color: $gray-600;
        }
    }
    .file-upload-drawer-link {
        .file-upload-drawer-filename {
            color: $body-color;
        }
        &:hover .file-upload-drawer-filename {
            color: $gray-800;
        }
    }
}

@include media-breakpoint-down(sm) {
    .form.form-fixed-actions .form-actions {
        left: 0;
        padding-left: $spacer*2;
        padding-right: $spacer*2;
    }
}