.filters {
    padding: $spacer 0 0 $spacer;
    display: flex;
    max-width: 900px;
    overflow-x: scroll;
}
.filter {
    width: 209px;
    flex-shrink: 0;
    padding-right: $spacer;
    position: relative;
}
.filter .form-control {
    margin-bottom: $spacer;
}
.filter .list-group {
    height: $spacer*30;
    overflow: scroll;
    padding-bottom: $spacer;
    display: block;
}
.filter .list-group-item {
    height: $spacer*3;
    border: none;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $gray-100; 
    &:hover {
        background: $gray-200;
    }
}
.filter .list-group-item .icon {
    stroke-width: 2px;
}
.filter .list-group-item.active {
    background-color: #17BEBB;
    border-color: #17BEBB;
    margin-top: 0;
}

.md-drppicker {
    font-family: inherit !important;
    padding: $spacer !important;
    box-shadow: none !important;
    filter: drop-shadow(0px 0px 8px rgba(45, 68, 92, 0.25)) drop-shadow(0 0 1px rgba(45, 68, 92, 0.25)) !important;
}
.md-drppicker.shown.drops-down-left {
    width: 510px !important;
    margin-top: $spacer*4 !important;
}
.md-drppicker td, .md-drppicker th {
    border: none !important;
}
.md-drppicker td.start-date.end-date {
    border-radius: 2rem !important;
}
.md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date {
    color: $body-color !important;
    opacity: .5 !important;
}
.md-drppicker td.available.in-range:hover, .md-drppicker th.available.in-range:hover {
    border-radius: 0;
    background: darken($secondary, 5%) !important;
    color: $dark !important;
}
.md-drppicker td.active.start-date, .md-drppicker th.active.start-date,
.md-drppicker td.active.start-date:hover, .md-drppicker th.active.start-date:hover {
    background-color: $teal !important;
    color: $white !important;
    border-radius: 2rem 0 0 2rem !important;
}
.md-drppicker td.active.end-date, .md-drppicker th.active.end-date,
.md-drppicker td.active.end-date:hover, .md-drppicker th.active.end-date:hover {
    background-color: $teal !important;
    color: $white !important;
    border-radius: 0 2rem 2rem 0 !important;
}
.md-drppicker td.active.end-date.start-date, .md-drppicker th.active.end-date.start-date,
.md-drppicker td.active.end-date.start-date:hover, .md-drppicker th.active.end-date.start-date:hover {
    border-radius: 2rem !important;
}

.md-drppicker td.in-range, .md-drppicker th.in-range {
    background: $secondary !important;
    color: $dark !important;
}

.md-drppicker td.available:hover, .md-drppicker th.available:hover {
    background-color: $secondary !important;
}

.md-drppicker .btn {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    min-width: 2.0625rem !important;
    min-height: 2.0625rem !important;
    line-height: .7rem !important;
    padding: 0.2815rem 0.75rem !important;
    font-size: 0.75rem !important;
    border-radius: 0.2rem !important;
    text-transform: capitalize !important;
    box-shadow: none !important;
    margin-left: $spacer !important;
    color: $white !important;
    background-color: $teal !important;
    border-color: $teal !important;
    &:hover,
    &:active,
    &:focus  {
        color: $white !important;
        background-color: darken($teal, 5%) !important;
        border-color: darken($teal, 5%) !important;
    }
    &.btn-default {
        color: $dark !important;
        background-color: $secondary !important;
        border-color: $secondary !important;
        &:hover,
        &:active,
        &:focus {
            color: $dark !important;
            background-color: darken($secondary, 5%) !important;
            border-color: darken($secondary, 5%) !important;
        }
    }
}

.md-drppicker th {
    color: $dark !important;
}

.md-drppicker td.active, .md-drppicker td.active {
    background-color: $teal !important;
}
.md-drppicker td.active, .md-drppicker td.active:hover {
    background-color: $teal !important;
}