.grid-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: $spacer*3;
    grid-row-gap: $spacer*3;
    margin-bottom: $spacer*3;
}
.grid-item {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: $spacer*2;
    transition: box-shadow .1s, transform .1s;
}
.grid-item-selected {
    transition: box-shadow .1s, transform .1s;
    box-shadow: 0 2px 11px rgba($dark, .125);
    transform: translateY(-5px);
}
.grid-item-image {
    position: relative;
    height: 0;
    padding-top: 75%;
    margin: (-$spacer*2) (-$spacer*2) 0 (-$spacer*2);
    a {
        padding: $spacer*2;
    }
    img {
        max-height: 100%;
        max-width: 100%;
    }
}
.grid-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: $spacer*4;
    background-color: $gray-100;
    margin: 0 (-$spacer*2) ($spacer*2) (-$spacer*2);
    padding: 0 ($spacer*2);
    box-shadow: 0 0 0 1px $border-color;
}
.grid-item-title {
    font-size: 1rem;
    margin: 0;
}
