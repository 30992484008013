$enable-responsive-font-sizes: true;
$white: #ffffff;
$gray-100: hsl(214, 64%, 98%);
$gray-200: hsl(210, 44%, 93%);
$gray-300: hsl(210, 24%, 87%);
$gray-400: hsl(211,23,74);
$gray-500: hsl(211, 23%, 59%);
$gray-600: hsl(211,23,44);
$gray-700: hsl(211,23,34);
$gray-800: hsl(211,34,27);
$gray-900: hsl(211, 44%, 19%);
$blue: #2C9EEB;
$pink: #EA3C55;
$teal: #17BEBB;
$red: #dd3327;
$yellow: #FFC914;
$primary: $pink;
$secondary: $gray-300;
$success: $teal;
$info: $blue;
$dark: $gray-800;
$darker: $gray-900;
$gray: $gray-300;
$theme-colors: (
    "dark-gray":  $gray-500,
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "darker":     $darker,
    "gray":       $gray
);
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
$enable-caret: false;
$enable-shadows: true;
$enable-print-styles: false;
$enable-responsive-font-sizes: true;
$spacer: 0.6875rem;
$spacers: (
    0: 0,
    1: $spacer,
    2: ($spacer * 2),
    3: ($spacer * 3),
    4: ($spacer * 4),
    5: ($spacer * 5)
);
$border-width: 1px;
$body-color: $gray-700;
$paragraph-margin-bottom: 1.375rem;
$grid-gutter-width: 33px;
$line-height-lg: 1.5714285714;
$line-height-sm: 1.8333333333;
$border-color: $gray-300;
$table-border-color: $gray-200;
$table-hover-bg: darken($gray-100, 2%);
$table-accent-bg: $gray-100;
$input-border-color: $border-color;
$input-placeholder-color: rgba($body-color, .5);
$border-radius: 0.3125rem;
$border-radius-lg: 0.375rem;
$border-radius-sm: 0.2rem;
$box-shadow-sm: 0 .125rem .25rem rgba($gray-900, .075);
$box-shadow: 0 .5rem 1rem rgba($gray-900, .15);
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, .175);
$font-family-sans-serif: Circular Std, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 0.875rem;
$font-size-sm: 0.75rem;
$font-size-lg: 1.125rem;
$line-height-base: 1.5714285714;

$h1-font-size: 1.875rem;
$h1-line-height: $spacer*4;

$h2-font-size: 1.6rem;
$h2-line-height: $spacer*4;

$h3-font-size: 1.3rem;
$h3-line-height: $spacer*3;

$h4-font-size: 1rem;
$h4-line-height: $spacer*2;

$h5-font-size: $font-size-base;
$h5-line-height: $spacer*2;

$h6-font-size: 0.8571428571rem;
$h6-line-height: $spacer*2;

$headings-line-height: 1.25;
$headings-color: $dark;
$display1-size: 9rem;
$text-muted: $gray-500;
$blockquote-small-color: $gray-500;
$blockquote-font-size: $font-size-lg;
$table-cell-padding: 0.6875rem;
$table-cell-padding-sm: 0.34375rem;
// $table-hover-bg: $gray-100;
$table-active-bg: $gray-100;
$input-box-shadow: none;
$btn-box-shadow: none;
$btn-block-spacing-y: 0.6875rem;
$label-margin-bottom: 0.6875rem;
$form-group-margin-bottom: 1.375rem;
$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y-sm: 0.2815rem;
$input-btn-padding-x-sm: .75rem;
$input-btn-padding-y-lg: 0.782rem;
$input-btn-padding-x-lg: 2rem;
$btn-font-size-sm: 0.75rem;
$form-text-margin-top: 0.6875rem;
$form-feedback-margin-top: 0;
$form-feedback-margin-bottom: 0.6875rem;
$navbar-light-color: rgba($gray-900, .5);
$navbar-light-hover-color: rgba($gray-900, .7);
$navbar-light-active-color: rgba($gray-900, .9);
$navbar-light-disabled-color: rgba($gray-900, .3);
$navbar-light-toggler-border-color: rgba($gray-900, .1);
$breadcrumb-bg: $white;
$breadcrumb-border-radius: 0;
$breadcrumb-padding-y: 0.688rem;
$breadcrumb-active-color: $body-color;
$thumbnail-padding: 0.6875rem;
$thumbnail-bg: $gray-100;
$thumbnail-border-color: $gray-400;
$thumbnail-box-shadow: none;
$list-group-item-padding-y: $spacer;
$list-group-item-padding-x: $spacer*2;
$font-weight-normal: 400;
$custom-file-button-bg: $secondary;

// Badges
$badge-font-size: 70%;
$badge-font-weight: $font-weight-normal;
$badge-padding-x: .9em;
$badge-pill-padding-x: .9em;
$badge-padding-y: 0;
$badge-border-radius: .2em;

// Modal
$modal-backdrop-bg: $gray-900;
$modal-panel-overlay-width: 264px;
$modal-panel-overlay-bg: $white;

$close-color: $body-color;

$sidebar-width: $spacer*20;

$dropdown-min-width: $sidebar-width - ($spacer*2);
$dropdown-padding-y: $spacer;
$dropdown-inner-border-radius: 0;
$dropdown-border-width: 0;
$dropdown-box-shadow: none;

$dropdown-link-color: $gray-700;
$dropdown-link-hover-color: $dark;
$dropdown-link-active-color: $white;
$dropdown-link-active-bg: $teal;

$dropdown-link-disabled-color: $gray-400 !default;

$dropdown-item-padding-y: 0.344rem;
$dropdown-item-padding-x: $spacer*2;

$dropdown-header-color: $dark;

$breadcrumb-padding-x: 2.0625rem;
$breadcrumb-divider: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9InJnYigxMjYsIDE0OSwgMTc0KSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hldnJvbi1yaWdodCI+PHBvbHlsaW5lIHBvaW50cz0iOSAxOCAxNSAxMiA5IDYiPjwvcG9seWxpbmU+PC9zdmc+Cg==);
$breadcrumb-item-padding: .75rem;

$enable-shadows: false;
$component-active-bg: $gray-500;

$custom-checkbox-indicator-border-radius: .2rem;

$table-head-color: $dark;
// $table-dark-color: $dark;

// Toolbar
$toolbar-width: $spacer*4;
$toolbar-bg: $dark;
$toolbar-color: $gray-500;
$toolbar-item-active-bg: $darker;
$toolbar-item-active-color: $white;
$toolbar-hr-color: rgba($toolbar-color, .5);

// Pagination
$pagination-border-width: 0;
$pagination-active-color: $gray-700;
$pagination-active-bg: transparent;


// List Group
// $list-group-bg: $gray-100;
$list-group-border-radius: $border-radius-sm;

$list-group-item-padding-y: $spacer*1.5;
$list-group-item-padding-x: $spacer*2;

$list-group-hover-bg: $gray-200;
$list-group-border-color: $border-color;
$list-group-active-color: $white;
$list-group-active-bg: $teal;

// Nav tabs
$nav-tabs-border-color:             transparent;
$nav-tabs-border-width:             0;
$nav-tabs-link-active-color:        $white;
$nav-tabs-link-active-bg:           $teal;


// Cards
$card-spacer-y:                     $spacer;
$card-spacer-x:                     $spacer*2;
$card-border-color:                 $border-color;
$card-cap-bg:                       $gray-100;

// Switch
$custom-switch-width: $spacer * 2;
$custom-switch-height: $spacer * 1.27272727;
$custom-switch-gutter: .2rem;
$custom-switch-indicator-size: .5rem;