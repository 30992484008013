.dashboard-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(154px, 154px));
    grid-column-gap: $spacer*2;
    grid-row-gap: $spacer*2;
}
.dashboard-item {
    display: flex;
    width: 154px;
    height: 187px;
    padding: $spacer*3 $spacer*2 $spacer*2 $spacer*2;
    background: $gray-100;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    color: $dark;
    border: 1px solid $border-color;
    transition: box-shadow .1s, transform .1s;
    &:hover {
        color: $dark;
        text-decoration: none;
        box-shadow: 0 2px 11px rgba($dark, .125);
        transform: translateY(-5px);
        transition: box-shadow .1s, transform .1s;
    }
}
.dashboard-item-icon {
    height: 72px;
    width: 72px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: $spacer*1.5;
    // background-color: $gray-500;
    // &.bg-secondary {
    //     color: $dark;
    // }
    .icon {
        height: 50%;
        width: 50%;
        stroke-width: 5%;
    }
}
.dashboard-item-text {
    text-align: center;
//     font-size: 1rem;
}