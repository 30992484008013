.sidebar-open .sidebar-nav,
.sidebar:hover .sidebar-nav,
.toolbar:hover+.sidebar .sidebar-nav {
    width: $sidebar-width;
    .list-group {
        opacity: 1;
    }
}

.sidebar-nav.has-transition,
.sidebar-open .sidebar-nav.has-transition {
    transition: width .3s;
    .list-group {
        transition: opacity .2s;
    }
}

.sidebar-open {
    .toolbar {
        margin-right: 0;
    }
    .sidebar {
        position: relative;
        top: unset;
        bottom: unset;
        // z-index: unset;
    }
    .toolbar+.sidebar {
        left: unset;
    }
    .sidebar-toggle {
        .icon {
            transform: translateX(-1px) rotate(-180deg);
            transition: transform .7s;
        }
    }
}

.sidebar {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
}
.toolbar+.sidebar {
    left: $spacer*4;
}
.sidebar-nav {
    width: $spacer*2;
    background-image: radial-gradient(circle at 50% 20%, lighten($dark, 6%), $dark);
    box-shadow: inset 1px 0 2px rgba(255,255,255,0.025);
    position: relative;
    overflow: hidden;
    padding-top: $spacer*4;
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        top: 0;
        bottom: 0;
        left: $sidebar-width - 1.25rem;
        background-image: linear-gradient(to right, rgba($dark, 0), rgba($dark, .5));
    }
    .list-group {
        width: 100%;
        opacity: 0;
    }
    .list-group-item {
        background-color: transparent;
        border: none;
        color: $gray-100;
        text-decoration: none;
        height: $spacer*4;
        white-space: nowrap;
        .icon {
            height: 18px;
            width: 18px;
            stroke-width: 1.5;
        }
        &:hover {
            background-color: $dark;
            color: $white;
            .list-group-item-icon {
                color: $white;
            }
        }
        &.active,
        &.active:hover {
            background-color: $white;
            color: $dark;
            box-shadow: inset 4px 0 0 $primary, 0 0 5px $dark;
            font-weight: 600;
            .list-group-item-icon {
                color: $dark;
                stroke-width: 2px;
            }
        }
        &.list-group-item--collapse {
            &.active,
            &.active:hover {
                box-shadow: inset 4px 0 0 $primary;
                background-color: darken($dark, 4%);
                color: $white;
                font-weight: 500;
                .icon {
                    color: $white;
                    stroke-width: 1.5;
                }
            }
            .feather-chevron-down {
                display: none;
            }
            .feather-chevron-up {
                display: block;
            }
            &.collapsed {
                .feather-chevron-down {
                    display: block;
                }
                .feather-chevron-up {
                    display: none;
                } 
            }
        }
    }
    span {
        display: flex;
        align-items: center;
    }
    .list-group-item-icon {
        color: $gray-500;
        margin-right: 15px;
    }
    .list-group-item-submenu {
        background-color: $dark;
        font-size: 0.8125rem;
        a {
            &:hover {
                background-color: darken($dark, 4%);
            }
        }
    }
}

.sidebar-toggle {
    background-color: $teal;
    height: $spacer*2;
    width: $spacer*2;
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 11px;
    right: -11px;
    z-index: 11;
    cursor: pointer;
    // opacity: 0;
    // transition: opacity .3s;
    // transition-delay: 1s;
    .icon,
    .icon svg {
        color: $white;
        height: 14px;
        width: 14px;
        stroke-width: 2.75px;
        transition: transform .7s;
    }
    .icon svg {
        display: block;
    }
}
// .sidebar:hover,
// .toolbar:hover ~ .sidebar {
//     .sidebar-toggle {
//         opacity: 1;
//         transition: opacity .3s;
//     }
// }

.flex-container {
    display: flex;
}
.flex-content {
    flex: 1 330px;
}
.sidebar-alt {
    width: $spacer*30;
}
@media screen and (max-width: 1200px) {
    .flex-container {
        flex-direction: column;
    }
    .flex-content {
        flex: 1;
    }
    .sidebar-alt {
        width: unset;
    }
}
@media screen and (max-width: 1400px) {
    .sidebar-open .flex-container {
        flex-direction: column;
    }
    .flex-content {
        flex: 1;
    }
    .sidebar-alt {
        width: unset;
    }
}
.sidebar-alt {
    background: $gray-100;
    padding: $spacer*2 $spacer*3 $spacer*6;
    min-height: calc(100vh - 44px);
    .table {
        th, td {
            padding-top: 0;
            padding-bottom: $spacer;
        }
        th {
            padding-left: 0;
        }
        td {
            padding-right: 0;
        }
    }
}

.sidebar-heading {
    user-select: none;
    cursor: pointer;
    height: $spacer*3;
    font-weight: 600;
    display: flex; 
    justify-content: space-between;
    align-items: center;
}

.sidebar-widget {
    margin-bottom: $spacer*2;
    *:last-child *:last-child {
        margin-bottom: 0;
    }
}

@include media-breakpoint-down(sm) {
    .toolbar + .sidebar {
        display: none;
    }
    .sidebar-open {
        .toolbar + .sidebar {
            display: block;
        }
    }
    .sidebar-nav {
        display: none;
        padding-top: $spacer*2;
        padding-bottom: $spacer*2;
        &:before {
            display: none;
        }
    }
    .sidebar-open .sidebar-nav, 
    .sidebar:hover .sidebar-nav, 
    .toolbar:hover + .sidebar .sidebar-nav {
        display: block;
        width: 100%;   
    }
    .sidebar-alt {
        padding-left: $spacer*2;
        padding-right: $spacer*2;
    }
}