.pagination {
    display: inline-flex;
}
.page-item .page-link,
.page-item:last-child .page-link,
.page-item:first-child .page-link {
    border-radius: $border-radius-sm;
    background: transparent;
}
.perpage-options {
    .btn .icon {
        stroke-width: 2.5;
        height: 18px;
        width: 18px;
    }
}