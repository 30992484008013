// Add additional styles here. For example, overwrite certain styles or add new components.
// Tip: You can use bootstrap's powerful mixins here!


//.alert-myalert {
//  @include alert-variant(#60667d, #1d1d1d, #f4fdff);
//}

//@each $color, $value in $theme-colors {
//  .alert-#{$color} {
//    box-shadow: 3px 3px theme-color-level($color, -3);
//  }
//}

html, body {
    height: 100%;
}
.content {
    height: 100vh;
    overflow: scroll;
}
.icon {
    height: 20px;
    width: 20px;
    stroke-width: 1.5;
}
.badge {
    min-width: 15px;
    display: inline-flex;
    align-items: center;
    min-height: $spacer*2;
    line-height: .9;
    &.badge-gray { 
        background-color: $gray;
        &:hover {
            background-color: darken($gray, 5%);
        }
    }
    i:last-child,
    .icon:last-child,
    i-feather:last-child {
        margin-left: $spacer*.5;
        cursor: pointer;
        &:hover {
            color: $dark;
        }
    }
}

.d-grid {
    display: grid;
}
.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.circle {
    display: inline-block;
    height: $spacer*.8;
    width: $spacer*.8;
    border-radius: 50%;
    @each $color, $value in $theme-colors {
        &.circle-#{$color} {
            background-color: $value;
            color: $white;
        }
        &.circle--#{$color} {
            background-color: $value;
            color: $white;
        }
    }
}
.circle-default {
    background-color: $secondary;
}

.deleted {
    text-decoration: line-through;
}

.overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

// .custom-control-label:empty {
//     &:before,
//     &:after {
//         left: unset;
//         right: 0;
//     }
// }

.fill-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.actions-left,
.actions-right {
    display: flex;
    align-items: center;
}

.card-body {
    padding: $card-spacer-y $card-spacer-x;
}

.img-container {
    height: 0;
    border: 1px solid #d6dee6;
    border-radius: $border-radius;
    position: relative;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .group-status {
        font-size: .75rem;
        color: #fff;
        border-radius: 2em;
        position: absolute;
        padding: .2rem .6rem;
        top: $spacer;
        right: $spacer;
        z-index: 1;
        &.group-status-pending {
            background-color: $warning;
        }
        &.group-status-issues {
            background-color: $danger;
        }
        &.group-status-approved {
            background-color: $teal;
        }
    }
    .no-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: -1px;
        text-align: center;
        border-radius: $border-radius $border-radius 0 0;
        background-color: rgba(255,255,255,0.9);
        cursor: pointer;
        .no-image-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            min-width: 255px;
            &:hover {
                .icon {
                    color: $gray-600;
                    transform: translateY(-5px);
                    transition: color .2s, transform .2s;
                }   
            }
            .icon {
                color: $gray-500;
                height: unset;
                width: unset;
                transition: color .2s, transform .2s;
            }
            .text {
                font-size: 1rem;
                display: block;
            }
        }
    }
}

.img-container-square {
    padding-top: 100%;
}
.img-container-16-9 {
    padding-top: 56.25%;
}

.form-group {
    .img-container {
        border-radius: $border-radius $border-radius 0 0;
        border-bottom: none;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        &+ .input-group {
            .custom-file-label {
                border-top-left-radius: 0;
            }
            *:last-child {
                border-top-right-radius: 0;
            }
            .input-group-append {
                .btn {
                    border-top-right-radius: 0;
                }
            }
        }
    }
    .img-container-remove {
        position: absolute;
        top: $spacer*2;
        right: $spacer*2;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        .feather {
            display: block;
        }
    }
}

.container-md {
    max-width: 737px;
    margin: 0 auto;
}
@include media-breakpoint-down(sm) {
    .container-md {
        padding: 0 $spacer*2;
    }
}
@include media-breakpoint-up(xl) {
    .container-md {
        padding: 0;
    }
}

.badge {
    vertical-align: top;
}

.define-part-areas {
    height: 56%;
}

.btr-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .panel {
    .panel-content {
        padding-bottom: $spacer*3;
    }
    .panel-content.has-footer {
        padding-bottom: $spacer*8;
    }
  }

// Reminders
#reminders {
    .btn-outline-primary {
        color: #fff;
    }
}
#chart {
    td {
        padding: 0 10px 10px 0;
    }
    td:first-child {
        padding-left: 0;
    }
    .task-item {
        transition: all .3s;
        box-shadow: 0 1px 4px rgba(35, 38, 41, 0.1); 
        display: block; 
        width: 200px; 
        height: 93px; 
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        border: 1px solid;
        &.task-item--default {
            color: black; 
            background-color: white;
            border: none;
        }
        &.task-item--event {
            color: white; 
            background-color: #4e82f4;
            border-color: #2e5fc9;
        }
        &.task-item--completed {
            color: white; 
            background-color: #5fb962;
            border-color: #429c45;
        }
        &.task-item--overdue {
            color: white; 
            background-color: #eb4d41;
            border-color: #cb1c10;
        }
        
        &.task-item--today {
            color: white; 
            background-color: #7648c5;
            border-color: #3f1885;
        }
        &.task-item--week {
            color: black; 
            background-color: #ffe70f;
            border-color: #e8d004;
        }
    }
}
.custom-file-preview {
    .current-image {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        margin-right: 22px;
        background-size: 101%;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
            box-shadow: inset 0 0 0 1px #d6dee6;
            background: #f7f9fd;
            z-index: -1;
            border-radius: 50%;
        }
    }
    .input-group {
        width: auto;
        flex: 1;
    }
}
.browse-images {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    .browse-image {
        width: 69px;
        height: 69px;
        display: inline-flex;
        background-size: cover;
        margin: 6px;
        border-radius: 50%;
        cursor: pointer;
        transition: all .1s;
        &:hover {
            transition: all .1s;
            transform: scale(1.5);
            box-shadow: 0 0 15px rgba(0,0,0,0.3);
        }
    }
}
.alert.alert-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000001;
}
body > .alert {
    border-radius: 0;
}
.alert button.close {
    padding: 0;
    top: 6px;
    right: 6px;
    background: none;
}


// Select 2 Dropdown

.select2-container {
	width: 100% !important;
}

.select2-container--default .select2-selection--single {
	border: 1px solid $border-color;
}
.select2-container .select2-selection--single {
	height: 44px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 42px;
	color: $body-color;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 16.5px;
	padding-right: 44px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: rgba($body-color, .5);
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 42px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 42px;
	width: 44px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: $body-color transparent transparent transparent;
}
.select2-search--dropdown {
	padding: 0;
	margin: -1px;
}
.select2-results__option {
	padding: 11px 16.5px;
}
.select2-dropdown,
.select2-container--default .select2-search--dropdown .select2-search__field {
	border-color: $border-color;
}
.select2-search--dropdown .select2-search__field {
	padding: 0.625rem 1rem;
	color: $body-color;
}
.select2-results__option .loading-results {
	color: $body-color;
}

@keyframes feather-spin {
    from {
        transform:rotate(0)
    } to { 
        transform:rotate(360deg)
    }
}

.feather-spin {
    animation: feather-spin 2s linear infinite;
}

.app-permissions-body-content p {
    line-height: $spacer*3;
    display: flex;
    align-items: center;
}
.app-permissions {
    border-bottom: 1px solid $border-color;
    &:last-child {
        border-bottom: none;
    }
    .app-icon {
        height: 44px;
        width: 44px;
        border-radius: 5px;
    }
    .app-permissions-body-content {
        margin-bottom: $spacer*2;
    }
    .select2-container .select2-selection--multiple {
        height: 33px;
        border: 1px solid $border-color;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        border: none;
        margin-right: 3px;
        margin-top: 3px;
        font-size: 11px;
        line-height: 25px;
        padding: 0 8px;
        border-radius: 2px;
        background: $secondary;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered {
        padding: 0 3px;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        margin-right: 5px;
        color: $dark;
    }
}
.select2-dropdown {
    border: 1px solid $border-color;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $gray-200;
    color: $body-color;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $teal;
    color: $white;
}
.select2-results__option {
    padding: 11px 16.5px;
}

// Color picker

.colorpicker.dropdown-menu {
    position: absolute;
}

// Alerts
.alert {
    padding: $spacer $spacer*1.5; 
    &.alert-dismissible {
        padding-right: $spacer*3;
    }
}

// Scale
.task-complete-btn {
    .icon {
        // opacity: 0;
        stroke-width: 2;
        color: $secondary;
    }
    &:hover {
        background-color: $teal;
        border-color: $teal;
        .icon {
            color: $white;
            // opacity: 1;
            display: inline-block;
        } 
    }
}

.planno-info-dropdown .dropdown-item {
    display: flex;
}
.planno-info-label {
    width: 200px; 
    display: inline-block;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.artwork-req-actions {
    > * {
        display: inline-flex;
        margin-left: $spacer;
    }
    button, label {
        background: none;
        border: none;
        color: $body-color;
        padding: 0;
        &[disabled=disabled] {
            color: $gray-400;
            &:hover {
                color: $gray-400;
            }
        }
        &:hover {
            color: $gray-900;
        }
    }
}

.product-artwork-approval {
    .approval-step {
        display: flex;
        justify-content: space-between;
        height: $spacer*3;
        // padding: 0 5px 0 15px;
        border-radius: $spacer*3;
        align-items: center;
        .approval-step-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: $spacer;
        }
        .approval-step-icon {
            display: flex; 
            align-items: center;
            justify-content: center;
            text-align: center;
        }
        .indicator {
            height: 8px;
            width: 8px;
            display: inline-block;
            border-radius: 50%;
            -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 3px 2px rgba(0,0,0,0.3);
            box-shadow: 0 0 0 2px #ffffff, 0 0 3px 2px rgba(0,0,0,0.3);
            margin-right: 15px;
            transform: translateY(-2px);
        }
        &.approval-step-warning {
            .indicator {
                background-color: $warning;
            }            
        }
        &.approval-step-success {
            .indicator {
                background-color: $success;
            }
        }
        &.approval-step-danger {
            .indicator {
                background-color: $danger;
            }
        }
    }
}

.leaflet-container a.leaflet-popup-close-button {
    height: auto !important;
    width: auto !important;
    padding: 10px 10px 0 0 !important;
    color: $gray-300 !important;
    &:hover {
        color: $gray-400 !important;
    }
}
.leaflet-popup-content-wrapper {
    min-width: 250px;
    border-radius: $border-radius !important;
    color: $body-color !important;
}
.leaflet-popup-content {
    margin: 18px 18px !important;
    .comment {
        padding-top: 18px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: $border-color;
        }
    }
}

.approval-groups-buttons {
    position: absolute;
    top: 1rem;
    left: 50%;
    z-index: 401;
    transform: translateX(-50%);
}

.clickable {
    cursor: pointer;
}

@include media-breakpoint-down(sm) {
    body {
        flex-direction: column;
    }

    .table-actions {
    //     flex-direction: column;
    //     .actions-left {
    //         margin-bottom: 1rem;
    //         justify-content: space-between;
    //         & > *:last-child {
    //             margin-right: 0 !important;
    //         }
    //     }
        .filter-btn, 
        .view-btn {
            display: none;
        }
    }
}

// .grid-items { 
//     grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
// }

@include media-breakpoint-down(sm) {
    .grid-items { 
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}