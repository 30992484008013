h1, .h1 {
    line-height: $h1-line-height;
    margin-bottom: $spacer*2;
}
h2, .h2 {
    line-height: $h2-line-height;
    margin-bottom: $spacer*2;
}
h3, .h3 {
    line-height: $h3-line-height;
    margin-bottom: $spacer;
}
h4, .h4 {
    line-height: $h4-line-height;
    margin-bottom: $spacer;
}
h5, .h5 {
    line-height: $h4-line-height;
    margin-bottom: $spacer;
}
h6, .h6 {
    line-height: $h4-line-height;
    margin-bottom: $spacer;
}

.lh-1 {
    line-height: $spacer;
}
.lh-2 {
    line-height: $spacer*2;
}
.lh-3 {
    line-height: $spacer*3;
}
.lh-4 {
    line-height: $spacer*4;
}
.h-1 {
    height: $spacer;
}
.h-2 {
    height: $spacer*2;
}
.h-3 {
    height: $spacer*3;
}
.h-4 {
    height: $spacer*4;
}