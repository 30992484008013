.custom-control-input:checked~.custom-control-label::before {
  background-color: $teal;
  border-color: $teal;
}
.custom-control-input:checked:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba($teal,.25);
}

.custom-switch {
  padding-left: $custom-switch-width + $custom-switch-gutter + .5rem;

  .custom-control-label {
    &::before {
      left: -($custom-switch-width + $custom-switch-gutter);
      width: $custom-switch-width;
      height: $custom-switch-height;
      pointer-events: all;
      // stylelint-disable-next-line property-blacklist
      border-radius: $custom-switch-indicator-border-radius;
      border: none;
      background-color: $gray-300;
      margin-top: 1px;
      margin-left: -.5rem;
    }

    &::after {
      top: calc(#{(($font-size-base * $line-height-base - $custom-switch-indicator-size) / 2)} + #{($custom-control-indicator-border-width * 2)} - #{$custom-switch-gutter});
      left: calc(#{-($custom-switch-width + $custom-switch-gutter)} + #{$custom-control-indicator-border-width * 3});
      width: $custom-switch-indicator-size;
      height: $custom-switch-indicator-size;
      background-color: $white;
      // stylelint-disable-next-line property-blacklist
      border-radius: $custom-switch-indicator-border-radius;
      @include transition(transform .15s ease-in-out, $custom-forms-transition);
      margin-top: 1px;
      margin-left: -.5rem;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: $white;
      transform: translateX($custom-switch-width - $custom-switch-indicator-size - ($custom-switch-gutter * 2));
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $teal;
    }
  }
}

// File
//
// Custom file input.

.custom-file.custom-file-sm {
  height: $input-height-sm;
  font-size: $input-font-size-sm;
}

.custom-file-sm .custom-file-input {
  height: $input-height-sm;
}

.custom-file-sm .custom-file-label {
  height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  line-height: calc(#{$input-height-sm} - #{$input-padding-y-sm*2} - 2px);
  @include border-radius($input-border-radius-sm);
  @include box-shadow($custom-file-box-shadow);

  &::after {
    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    line-height: calc(#{$input-height-sm} - #{$input-padding-y-sm*2} - 2px);
    font-size: $input-font-size-sm;
    @include border-radius(0 $input-border-radius-sm $input-border-radius-sm 0);
  }
}