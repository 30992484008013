.nav--apps {
    .nav-link {
        padding: $spacer;
        color: $body-color;
        display: flex;
        align-items: center;
        &.active,
        &:hover {
            background: $gray-100;
            color: $gray-800;
        }
        &.active {
            font-weight: 600;
        }
        .app-icon {
            width: $spacer*3;
            border-radius: $border-radius-sm;
        }
    }
}

.nav-tabs {
    border-bottom: 2px solid $teal;
    .nav-item {
        // max-width: 340px;
        margin-right: $spacer*2;
        &:last-child {
            margin-right: 0;
        }
    }
    .nav-link {
        background-color: $gray-100;
        color: $body-color;
        &:hover:not(.active) {
            background-color: darken($gray-100, 2%);
        }
    }
    .nav-link {
        height: 2.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.vertical-tabs {
    border-radius: $border-radius;
    border: 1px solid $border-color;
    background: $gray-100;
    .tab-content {
        background: $white;
        z-index: 1;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
    .col-3 {
        box-shadow: inset -1px 0 0 0 $border-color;
    }
    .nav-link {
        height: $spacer*4;
        display: flex;
        align-items: center;
        &:hover {
            background-color: $white;
        }
        // justify-content: center;
        &.active {
            background: $white;
            box-shadow: 0 0 0 1px $border-color;
        }
        &:first-child {
            border-top-left-radius: $border-radius;
        }
    }
}

@include media-breakpoint-down(sm) {
    .nav-tabs {
        padding-left: $spacer*2;
        .nav-item {
            margin-bottom: $spacer;
            .nav-link {
                border-radius: $border-radius;
            }
            &:last-child {
                .nav-link {
                    margin-right: $spacer*2;
                }
            }
        }
    }
}