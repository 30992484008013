.btn {
    min-width: $spacer*4;
    min-height: $spacer*4;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .icon {
        stroke-width: 2px;
    }
}
.btn-sm {
    min-width: $spacer*3;
    min-height: $spacer*3;
    line-height: .7rem;
}
.btn-lg {
    min-width: $spacer*5;
    min-height: $spacer*5;
}

.btn-outline-secondary,
.btn-outline-secondary.disabled, 
.btn-outline-secondary:disabled  {
    color: $dark;
}

.btn.btn-unstyled {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: unset;
    min-width: unset;
    min-height: unset;
}

button:focus,
.btn:focus {
    outline: none !important;
}