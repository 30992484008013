.list-group-item {
    border-radius: $list-group-border-radius;
}
button.list-group-item {
    cursor: pointer;    
}
.list-group-sm .list-group-item {
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    font-size: $font-size-sm;
    height: $spacer*3;
}