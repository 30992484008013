.panel-content {
    // display: flex;
    // flex-direction: column;
    height: 100%;
    overflow-y: auto;
}
.panel-body {
    /* flex-grow: 1; */
    // display: flex;
    // flex-direction: column;
    // overflow-x: hidden;
    // overflow-y: scroll;
    padding-bottom: $spacer*3;
}
.panel-content.has-footer .panel-body {
    padding-bottom: $spacer*12;
}
.panel-footer {
    border-top: 1px solid #eee;
    padding: 1rem 0 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #ffffff;
    // z-index: 100;
    // flex: 0 0 3.5rem;
}
.panel-footer .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.panel-container.open {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.5);
    z-index: 99;
}
.panel {
    position: fixed;
    top: 0;
    left: 110%;
    right: auto;
    bottom: 0;
    width: 40vw;
    min-width: 600px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    z-index: 100;
    // overflow-y: scroll;
    // padding: $spacer*3;
    // height: 100vh;
}
.open .panel-right {
    left: auto;
    right: 0;
}
@include media-breakpoint-down(xl) {
    // .panel {
    //     min-width: unset;
    //     // padding: $spacer*3;
    // }
    .open .panel{
        left: $spacer*6;
        left: 550px;
        width: auto;
    }
    .sidebar-open .open .panel {
        left: 550px;
    }
}


@include media-breakpoint-down(lg) {
    .panel {
        min-width: unset;
    }
    .open .panel{
        left: $spacer*6;
        right: 0;
        width: auto;
    }
    .sidebar-open .open .panel {
        left: $sidebar-width + $spacer*4;
    }
}

@include media-breakpoint-down(sm) {
    .open .panel{
        left: 0;
    }
    .sidebar-open .open .panel {
        left: 0;
    }
    .panel-footer {
    
    }
}