.modal--panel {
    background: $modal-panel-overlay-bg;
    position: fixed;
    height: 100vh;
    transform: translateX(-$modal-panel-overlay-width);
    width: $modal-panel-overlay-width;
    transition: transform .3s;
    display: block !important;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.modal-open {
    .modal--panel.show {
        transform: translateX(0);
        transition: transform .3s;
    }
}

button.close {
    position: absolute;
    top: $spacer*1.5;
    right: $spacer*1.5;
    text-shadow: none;
    height: $spacer*3;
    width: $spacer*3;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    z-index: 9;
    &:hover {
        color: unset;
        background: r#fff;
    }
    .icon,
    .icon .feather {
        display: block;
        height: 21px;
        width: 21px;
    }
}

.modal-footer {
    border: none;
}

.modal-fs {
    position: fixed;
    top: 0;
    left: 1.75em;
    right: 1.75em;
    bottom: 1.75em;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 0;
    .modal-content,
    .modal-content > .position-relative {
        height: 100%;
    }
}