.breadcrumb {
    box-shadow: inset 0 -1px 0 $border-color;
}
.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.6875rem;
}
// .breadcrumb {
//     position: fixed;
//     left: 0;
//     right: 0;
//     z-index: 1;
// }
.breadcrumb-nav {
    position: fixed;
    left: $toolbar-width + ($spacer*2);
    right: 0;
    top: 0;
    z-index: 10;
}

.sidebar-open .breadcrumb-nav {
    left: $toolbar-width + $sidebar-width;
}

@include media-breakpoint-down(sm) {
    .breadcrumb {
        padding-left: $spacer*2;
        padding-right: $spacer*2;
    }
    .breadcrumb-nav {
        position: static;
    }
}
