.table {
    margin-bottom: $spacer*2;
}
.table th {
    color: $dark;
}
.table thead th,
.table td, .table th {
    border: none;
}
.table td, .table th {
    box-shadow: inset 0 -1px 0 $border-color;
    line-height: $spacer*2;
}
.table thead th {
    box-shadow: inset 0 -2px 0 $border-color;
    white-space: nowrap;
    .icon {
        width: 14px;
        height: 14px; 
        margin-top: -1px; 
        stroke-width: 3px;
    }
}

.table-borderless th,
.table-borderless th,
.table-borderless td, 
.table-borderless thead th, 
.table tbody tr:last-child th,
.table tbody tr:last-child td {
    box-shadow: none;
}

.header-icons { 
    .fa-sort-up,
    .fa-sort-down {
        font-size: 12px;
        color: $gray-400;
        &.active {
            color: $dark;
        }
    }
    .fa-sort-up {
        transform: translateX(6.75px);
    }
}