.tree-list-bom {
    margin: 0 0 25px 0;
    padding: 0;
    li {
        list-style: none;
        margin-top: 2px;
        .bom-item {
            background: $gray-100;
            border-radius: $border-radius; 
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    > li {
        margin-top: 10px;
        > .bom-item {
            background: darken($gray-100, 2%);
        }
    }
    .bom-item-image {
        height: 44px;
        width: 44px;
        background: $white;
        margin-right: $spacer*2;
        text-align: center;
        border-radius: $border-radius;
        overflow: hidden;
        img {
            max-height: 100%;
            max-width: 100%;
        }
    }
    ul {
        overflow: hidden;
    }
}
.bom-search {
    position: relative;
    margin-bottom: $spacer*2;
    .search-panel {
        max-height: 319px;
        overflow: scroll;
        position: absolute;
        display: block;
        top: 100%;
        left: 0;
        right: 0;
        background-color: $white;
        box-shadow: 0px 0px 8px rgba(45, 68, 92, 0.25), 0 0 1px rgba(45, 68, 92, 0.25);
        border-radius: 0 0 $border-radius $border-radius;
    }
    .search-results.no-results {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 71px;
    }
    &.bom-search-open {
        .input-group-prepend .custom-select {
            border-bottom-left-radius: 0;
        }
        .input-group-append .btn {
            border-bottom-right-radius: 0;
        }
    }
    .list-item {
        cursor: pointer;
        &:hover {
            background: $gray-100;
        }
    }
    .list-item-image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $spacer*3;
        img {
            max-height: 100%;
        } 
    }
}
.bom-search-fields {
    z-index: 10;
}



#partPreview {
    background-color: #f1f2f3;
    position: absolute;
    -webkit-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-box-shadow: inset 0 0 0 1px #9fa6ac;
    box-shadow: inset 0 0 0 1px #9fa6ac;
}

.part-preview-continer {
    background: #fff;
    padding: 20px;
}

.define-part-areas {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid $border-color;
}

.define-part-areas .col-md-4 {
    border-right: 1px solid $border-color;
}

.part-preview-item {
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 'cover';
}

.part-preview-item:before {
    content: "";
    display: inline-block;
    text-align: center;
    position: absolute;
    left: 50%;
    width: 1px;
    margin-left: -.5px;
    height: 5px;
    bottom: -2px;
    background: #FF2852;
}

.part-preview-item .product-area {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #9fa6ac;
}

.part-preview-item .gravity-height {
    position: absolute;
    width: 100%;
    background: #fff;
    border: 1px dashed #9fa6ac;
    opacity: 0.5;
    z-index: 90;
}

.part-preview-item .gravity-height-back {
    position: absolute;
    width: 100%;
    background: #fff;
    border: 1px dashed #9fa6ac;
    opacity: 0.5;
    z-index: 80;
}