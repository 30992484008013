.dropup .dropdown-toggle::after,
.dropdown .dropdown-toggle::after {
    display:none;
}

.dropdown-menu-header {
    height: $spacer*4;
    padding: 0 $spacer 0$spacer*2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    background: $gray-100;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}
.dropdown-menu-header-sm {
    font-size: $font-size-sm;
}
.dropdown-menu-header-left,
.dropdown-menu-header-right {
    white-space: nowrap;
    display: flex;
}

.dropdown-menu {
    margin-top: 18px;
    position: relative;
    box-shadow: none;
    -webkit-filter: drop-shadow(0px 0px 8px rgba($dark, 0.25)) drop-shadow(0 0 1px rgba($dark, .25));
    filter: drop-shadow(0px 0px 8px rgba($dark,.25)) drop-shadow(0 0 1px rgba($dark, .25));
    &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: 10;
    }
    &[x-placement='bottom-start'],
    &[x-placement='bottom-end'] {
        &:before {
            height: 18px;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 11px solid $white
        }
    }
    &[x-placement='top-start'],
    &[x-placement='top-end'] {
        margin-bottom: $spacer;
        margin-top: 0;
        &:before {
            bottom: -18px;
            height: 18px;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 11px solid $white;
        }
    }

    &[x-placement='bottom-start']:before {
        left: $spacer*1.2;
        top: -18px;
    }
    &[x-placement='bottom-end']:before {
        right: $spacer*1.2;
        top: -18px;
    }
    &[x-placement='top-start']:before {
        left: $spacer*1.2;
        bottom: -18px;
    }
    &[x-placement='top-end']:before {
        right: $spacer*1.2;
        bottom: -18px;
    }
}

.dropdown-menu-filters.dropdown-menu[x-placement='bottom-end']:before {
    border-bottom-color: $gray-100; 
}
.user-menu .dropdown-menu[x-placement='bottom-start'] {
    margin-left: $spacer;
    z-index: 2000;
    margin-top: 9px;
    &:before {
        left: -18px;
        bottom: 24px;
        top: unset;
        width: 18px;
        border-bottom: 9px solid transparent;
        border-top: 9px solid transparent;
        border-right: 11px solid #fff;
    }
}