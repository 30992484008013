.toolbar {
    width: $toolbar-width;
    background-color: $toolbar-bg;
    // height: 100%;
    display: flex;
    color: $toolbar-color;
    justify-content: space-between;
    flex-direction: column;
    margin-right: $spacer*2;
    hr {
        background-color: $toolbar-hr-color;
        margin: 10px 8px $spacer 8px;
    }
}
.bg-darker .toolbar {
    box-shadow: 0 0 10px 0 #1C2631;
}
.toolbar-bottom {
    padding-bottom: 22px;
}
.toolbar-brand,
.toolbar-item {
    height: $toolbar-width;
    width: $toolbar-width;
}
.toolbar-brand {
    background-color: $primary;
    .app-icon {
        width: 100%;
    }
}
.toolbar-item {
    color: $toolbar-color;
    display: flex;
    padding: 0;
    cursor: pointer;
    text-align: center;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus {
        color: $toolbar-item-active-color;
    }
    &.active {
        background-color: $toolbar-item-active-bg;
        color: $toolbar-item-active-color;
    }
    .icon {
        display: table-cell;
        vertical-align: middle;
    }
    img {
        height: 24px;
        width: 24px;
        border-radius: 50%;
    }
}
button.toolbar-item {
    background-color: transparent;
    border: none;
    outline: none;
}


@include media-breakpoint-down(sm) {
    .toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: $toolbar-width;
    }
    .toolbar-bottom {
        padding-bottom: 0;
        display: flex;
        flex-direction: row;
    }
}