.content {
    flex: 1 1 auto;
    position: relative;
    z-index: 0;
    padding-top: $spacer*4;
    // &:hover {
    //     z-index: 0;
    // }
}

@include media-breakpoint-down(sm) {
    .content {
        padding-top: 0;
    }
}